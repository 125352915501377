import _, { sumBy } from 'lodash'
import { BasketProps } from '../../components/Basket/Basket'
import { CostsTableProps } from '../../components/CostsTable/CostsTable'
import { ProductTableCategoryProps } from '../../components/ProductTableCategory/ProductTableCategory'
import {
    AccountType,
    BillMode,
    Discount,
    Option,
    OptionCategory,
    Product,
    ProductCategory,
    VoucherData,
} from '../../graphql/types'
import { AvailabilityCheckState } from '../../store/AvailabilityCheck/AvailabilityCheck.reducer'
import { BankDetailsState } from '../../store/BankDetails/BankDetails.reducer'
import {
    GeneralState,
    InstallationDetailsState,
    SelectedOptionCategory,
    SelectedProductCategory,
} from '../../store/GeneralState/GeneralState.reducer'
import { Customize } from '../customize'

export const getSelectedProductCategoryFromData = (
    productCategories: ProductCategory[],
    id: string,
): ProductCategory | undefined => {
    return productCategories.find((availablePC: ProductCategory) => availablePC.id === id)
}

export const formatSelectedProductCategory = (
    customizeJsData: Customize | undefined,
    bankDetailsState: BankDetailsState,
    optionsMultipleSelect: Map<string, number>,
    configuration: Map<string, string | string[]>,
    availableProductCategories: ProductCategory[],
    selectedProductCategory: SelectedProductCategory,
    processUniqueFeeList: string[],
    processUniqueVoucherList: string[],
    b2b: boolean,
    vouchers: VoucherData[],
    isForVZF: boolean,
): ProductTableCategoryProps => {
    const id = selectedProductCategory.id
    const productCategoryToReturn: ProductTableCategoryProps = {
        products: [],
        title: '',
    }

    const selectedProductCategoryFromData = getSelectedProductCategoryFromData(availableProductCategories, id)

    if (selectedProductCategoryFromData && selectedProductCategory.selectedProduct) {
        const selectedProductFromData: Product | undefined = selectedProductCategoryFromData.products.find(
            (product: Product) =>
                selectedProductCategory.selectedProduct && product.id === selectedProductCategory.selectedProduct.id,
        )
        if (selectedProductFromData) {
            productCategoryToReturn.title = selectedProductCategoryFromData.title
            const selectedProductFromDataPrice = b2b ? selectedProductFromData.priceNet : selectedProductFromData.gross
            let oldPrice: number | undefined
            let newPrice = selectedProductFromDataPrice
            let description: string[] | undefined
            if (selectedProductFromData.discounts.length > 0) {
                oldPrice = selectedProductFromDataPrice
                newPrice =
                    oldPrice - sumBy(selectedProductFromData.discounts, (d: Discount) => (b2b ? d.priceNet : d.gross))
                description = selectedProductFromData.discounts.map((discount) => discount.descriptionBasket)
            }

            productCategoryToReturn.products.push({
                category: selectedProductCategoryFromData.identifier,
                description: selectedProductFromData.titleBasket,
                value: {
                    oldPrice,
                    newPrice,
                    description,
                    billMode: selectedProductFromData.billMode,
                    info: !!selectedProductFromData.information
                        ? { title: selectedProductFromData.information }
                        : undefined,
                },
            })

            // Add voucher if any
            if (!isForVZF) {
                vouchers.forEach((voucher) => {
                    voucher.products.forEach((voucherProduct) => {
                        if (
                            voucherProduct.articleId === selectedProductFromData.id &&
                            processUniqueVoucherList.indexOf(voucher.id) === -1
                        ) {
                            productCategoryToReturn.products.push({
                                category: 'priceComponents',
                                description: voucher.name,
                                value: {
                                    billMode: BillMode.VOUCHER,
                                    newPrice: voucher.value.value,
                                    info: voucher.infoText.trim().length > 0 ? { title: voucher.infoText } : undefined,
                                },
                            })
                            processUniqueVoucherList.push(voucher.id)
                        }
                    })
                })
            }

            for (const fee of selectedProductFromData.fees) {
                const invoiceSend = configuration.get('invoiceSend')
                const showInvoiceSendFee =
                    !isForVZF &&
                    customizeJsData &&
                    customizeJsData?.bankDetails.useInvoiceFee &&
                    customizeJsData?.bankDetails.useInvoiceFee.feeId === fee.id &&
                    ((customizeJsData?.bankDetails.useInvoiceFee.visibleB2B === true && b2b === true) ||
                        b2b === false) &&
                    invoiceSend &&
                    invoiceSend === 'viaMail'

                const showTransferFee =
                    !isForVZF &&
                    customizeJsData &&
                    customizeJsData?.bankDetails.useTransferFee &&
                    customizeJsData?.bankDetails.useTransferFee.feeId === fee.id &&
                    ((customizeJsData?.bankDetails.useTransferFee.visibleB2B === true && b2b === true) ||
                        b2b === false) &&
                    bankDetailsState.accountType !== AccountType.IBAN

                if (
                    (fee.processUnique && processUniqueFeeList.indexOf(fee.id) != -1) ||
                    (fee.optional && !showInvoiceSendFee && !showTransferFee)
                ) {
                    continue
                } else if (fee.processUnique) {
                    processUniqueFeeList.push(fee.id)
                }
                const feePrice = b2b ? fee.priceNet : fee.gross
                let oldPrice: number | undefined
                let newPrice = feePrice
                let description: string[] | undefined
                if (fee.discounts.length > 0) {
                    oldPrice = feePrice
                    newPrice = oldPrice - sumBy(fee.discounts, (d: Discount) => (b2b ? d.priceNet : d.gross))
                    description = fee.discounts.map((discount) => discount.descriptionBasket)
                }
                productCategoryToReturn.products.push({
                    category: 'priceComponents',
                    description: fee.titleBasket,
                    value: {
                        oldPrice,
                        newPrice,
                        description,
                        billMode: fee.billMode,
                    },
                })
            }

            selectedProductCategory.selectedProduct.productTypes.forEach((productType) => {
                const matchingProductType = selectedProductFromData.productTypes.find((pt) => pt.id === productType.id)
                if (matchingProductType && matchingProductType.optional) {
                    const matchingOptionPrice = b2b ? matchingProductType.priceNet : matchingProductType.gross
                    let description: string[] | undefined
                    let oldPrice: number | undefined
                    let newPrice = matchingOptionPrice as number
                    if (matchingProductType.discounts.length > 0) {
                        oldPrice = matchingOptionPrice as number
                        newPrice =
                            oldPrice -
                            sumBy(matchingProductType.discounts, (d: Discount) => (b2b ? d.priceNet : d.gross))
                        description = matchingProductType.discounts.map((discount) => discount.descriptionBasket)
                    }

                    productCategoryToReturn.products.push({
                        category: matchingProductType.identifier,
                        description: matchingProductType.title,
                        value: {
                            billMode: matchingProductType.billMode ? matchingProductType.billMode : BillMode.IGNORE,
                            oldPrice,
                            newPrice,
                            description,
                            info: matchingProductType.information
                                ? {
                                      title: matchingProductType.information,
                                  }
                                : undefined,
                        },
                    })
                }

                productType.optionCategories.forEach((selectedOptionCategory: SelectedOptionCategory) => {
                    selectedProductFromData.productTypes.forEach((productType) => {
                        const matchingOptionCategory = productType.category.find(
                            (optionCategoryFromData: OptionCategory) =>
                                optionCategoryFromData.id === selectedOptionCategory.id,
                        )
                        if (matchingOptionCategory) {
                            selectedOptionCategory.selectedOptions.forEach((optionId: string) => {
                                const matchingOption = matchingOptionCategory.options.find(
                                    (option: Option) => option.id === optionId,
                                )
                                if (matchingOption) {
                                    const matchingOptionPrice = b2b ? matchingOption.priceNet : matchingOption.gross
                                    let oldPrice: number | undefined
                                    let newPrice = matchingOptionPrice
                                    let description: string[] | undefined
                                    if (matchingOption.discounts.length > 0) {
                                        oldPrice = matchingOptionPrice
                                        newPrice =
                                            oldPrice -
                                            sumBy(matchingOption.discounts, (d: Discount) =>
                                                b2b ? d.priceNet : d.gross,
                                            )
                                        description = matchingOption.discounts.map(
                                            (discount) => discount.descriptionBasket,
                                        )
                                    }

                                    let optionsMultipleSelectCounterLabel = ''
                                    const optionsMultipleSelectCounter = optionsMultipleSelect.get(matchingOption.id)
                                    if (optionsMultipleSelectCounter && optionsMultipleSelectCounter > 1) {
                                        optionsMultipleSelectCounterLabel = optionsMultipleSelectCounter + ' x '
                                    }

                                    if (!isForVZF) {
                                        vouchers.forEach((voucher) => {
                                            voucher.products.forEach((voucherProduct) => {
                                                if (
                                                    voucherProduct.articleId === matchingOption.id &&
                                                    processUniqueVoucherList.indexOf(voucher.id) === -1
                                                ) {
                                                    productCategoryToReturn.products.push({
                                                        category: 'priceComponents',
                                                        description: voucher.name,
                                                        value: {
                                                            billMode: BillMode.VOUCHER,
                                                            newPrice: voucher.value.value,
                                                            description: [`${voucher.value.month} Monate`],
                                                            info:
                                                                voucher.infoText.trim().length > 0
                                                                    ? { title: voucher.infoText }
                                                                    : undefined,
                                                        },
                                                    })
                                                    processUniqueVoucherList.push(voucher.id)
                                                }
                                            })
                                        })
                                    }

                                    const category = matchingOption.isHardware ? 'Hardware' : 'Service'
                                    productCategoryToReturn.products.push({
                                        category: productType.identifier + category,
                                        description: optionsMultipleSelectCounterLabel + matchingOption.titleBasket,
                                        value: {
                                            oldPrice,
                                            billMode: matchingOption.billMode,
                                            newPrice,
                                            multiple: optionsMultipleSelectCounter ? optionsMultipleSelectCounter : 0,
                                            description,
                                            info: !!matchingOption.information
                                                ? { title: matchingOption.information }
                                                : undefined,
                                        },
                                    })

                                    for (const fee of matchingOption.fees) {
                                        if (
                                            (fee.processUnique && processUniqueFeeList.indexOf(fee.id) != -1) ||
                                            fee.optional
                                        ) {
                                            continue
                                        } else if (fee.processUnique) {
                                            processUniqueFeeList.push(fee.id)
                                        }
                                        const matchingOptionFeePrice = b2b ? fee.priceNet : fee.gross
                                        let oldPrice: number | undefined
                                        let newPrice = matchingOptionFeePrice
                                        let description: string[] | undefined
                                        if (fee.discounts.length > 0) {
                                            oldPrice = matchingOptionFeePrice
                                            newPrice =
                                                oldPrice -
                                                sumBy(fee.discounts, (d: Discount) => (b2b ? d.priceNet : d.gross))
                                            description = fee.discounts.map((discount) => discount.descriptionBasket)
                                        }
                                        productCategoryToReturn.products.push({
                                            category: 'priceComponents',
                                            description: fee.titleBasket,
                                            value: {
                                                billMode: fee.billMode,
                                                oldPrice,
                                                newPrice,
                                                description,
                                            },
                                        })
                                    }
                                }
                            })
                        }
                    })
                })
            })
        }
    }
    return productCategoryToReturn
}

// const getAssociatedVouchers = (voucherList: Voucher[], processUniqueFeeList: string[], id: string): Voucher[] => {
//     const associatedVouchers: Voucher[] = []
//     for (const voucher of voucherList) {
//         const index = voucher.products.findIndex((p) => p.articleId === id)
//         if (index !== -1 && processUniqueFeeList.findIndex((e) => e === voucher.id) === -1) {
//             associatedVouchers.push(voucher)
//             processUniqueFeeList.push(voucher.id)
//         }
//     }
//     return associatedVouchers
// }

// const calculateAssociatedVouchers = (associatedVoucher: Voucher[], price: number, month: number): number => {
//     let newPrice = price
//     for (const voucher of associatedVoucher) {
//         if (voucher.type !== 'credit' && (voucher.value.isOnce || month < voucher.value.month)) {
//             if (voucher.value.currency === VoucherCurrency.EURO) {
//                 newPrice = newPrice - voucher.value.value
//             }
//         }
//     }
//     if (newPrice < 0) {
//         return 0
//     }

//     return price
// }

const calculateBasketCosts = (
    customizeJsData: Customize | undefined,
    bankDetailsState: BankDetailsState,
    optionsMultipleSelect: Map<string, number>,
    configuration: Map<string, string | string[]>,
    availableProductCategories: ProductCategory[],
    selectedProductCategory: SelectedProductCategory[],
    installationDetails: InstallationDetailsState,
    b2b: boolean,
    isForVZF: boolean,
): CostsTableProps | undefined => {
    const costTableToReturn: CostsTableProps = {
        monthlyCost: 0,
        monthlyIncrements: [],
        oneTimeCost: 0,
        oneTimeItems: [],
    }
    let monthlyIncrementsMap: Map<number, number> = new Map()
    const processUniqueFeeList: string[] = []

    selectedProductCategory.forEach((selectedProductCategory: SelectedProductCategory) => {
        const id = selectedProductCategory.id
        const selectedProductCategoryFromData = getSelectedProductCategoryFromData(availableProductCategories, id)

        if (selectedProductCategoryFromData && selectedProductCategory.selectedProduct) {
            const selectedProductFromData = selectedProductCategoryFromData.products.find(
                (product: Product) =>
                    selectedProductCategory.selectedProduct &&
                    product.id === selectedProductCategory.selectedProduct.id,
            )
            if (selectedProductFromData) {
                const selectedProductFromDataPrice = b2b
                    ? selectedProductFromData.priceNet
                    : selectedProductFromData.gross
                // Monthly Costs for Products
                if (selectedProductFromData.billMode === BillMode.RECURRING_MONTHLY) {
                    costTableToReturn.monthlyCost += selectedProductFromDataPrice
                } else if (selectedProductFromData.billMode === BillMode.ONE_TIME) {
                    // One Time Costs for Products
                    costTableToReturn.oneTimeCost += selectedProductFromDataPrice
                }

                selectedProductFromData.discounts.forEach((discount) => {
                    const discountPrice = b2b ? discount.priceNet : discount.gross
                    if (discount.billMode === BillMode.ONE_TIME) {
                        costTableToReturn.oneTimeCost -= discountPrice
                    } else if (discount.billMode === BillMode.RECURRING_MONTHLY) {
                        discount.monthlyDiscounts.forEach((monthlyDiscount) => {
                            const monthlyDiscountPrice = b2b ? monthlyDiscount.priceNet : monthlyDiscount.gross
                            if (monthlyDiscount.initMonth === 0 && monthlyDiscount.endMonth === 0) {
                                costTableToReturn.monthlyCost -= monthlyDiscountPrice
                            } else {
                                for (let i = monthlyDiscount.initMonth; i < monthlyDiscount.endMonth; i++) {
                                    const currentMapValue = monthlyIncrementsMap.get(i)
                                    monthlyIncrementsMap.set(
                                        i,
                                        currentMapValue ? currentMapValue + monthlyDiscountPrice : monthlyDiscountPrice,
                                    )
                                }
                            }
                        })
                    }
                })

                selectedProductFromData.fees.forEach((fee) => {
                    const invoiceSend = configuration.get('invoiceSend')
                    const showInvoiceSendFee =
                        !isForVZF &&
                        customizeJsData &&
                        customizeJsData?.bankDetails.useInvoiceFee &&
                        customizeJsData?.bankDetails.useInvoiceFee.feeId === fee.id &&
                        ((customizeJsData?.bankDetails.useInvoiceFee.visibleB2B === true && b2b === true) ||
                            b2b === false) &&
                        invoiceSend &&
                        invoiceSend === 'viaMail'
                    const showTransferFee =
                        !isForVZF &&
                        customizeJsData &&
                        customizeJsData?.bankDetails.useTransferFee &&
                        customizeJsData?.bankDetails.useTransferFee.feeId === fee.id &&
                        ((customizeJsData?.bankDetails.useTransferFee.visibleB2B === true && b2b === true) ||
                            b2b === false) &&
                        bankDetailsState.accountType !== AccountType.IBAN

                    if (
                        (fee.processUnique && processUniqueFeeList.indexOf(fee.id) != -1) ||
                        (fee.optional && !showInvoiceSendFee && !showTransferFee)
                    ) {
                        return
                    } else if (fee.processUnique) {
                        processUniqueFeeList.push(fee.id)
                    }
                    const selectedProductFromDataFeePrice = b2b ? fee.priceNet : fee.gross
                    if (fee.billMode === BillMode.RECURRING_MONTHLY) {
                        costTableToReturn.monthlyCost += selectedProductFromDataFeePrice
                    } else if (fee.billMode === BillMode.ONE_TIME) {
                        costTableToReturn.oneTimeCost += selectedProductFromDataFeePrice
                    }
                    fee.discounts.forEach((discount) => {
                        const feeDiscountPrice = b2b ? discount.priceNet : discount.gross
                        if (discount.billMode === BillMode.ONE_TIME) {
                            costTableToReturn.oneTimeCost -= feeDiscountPrice
                        } else if (discount.billMode === BillMode.RECURRING_MONTHLY) {
                            discount.monthlyDiscounts.forEach((monthlyDiscount) => {
                                const monthlyDiscountPrice = b2b ? monthlyDiscount.priceNet : monthlyDiscount.gross
                                if (monthlyDiscount.initMonth === 0 && monthlyDiscount.endMonth === 0) {
                                    costTableToReturn.monthlyCost -= monthlyDiscountPrice
                                } else {
                                    for (let i = monthlyDiscount.initMonth; i < monthlyDiscount.endMonth; i++) {
                                        const currentMapValue = monthlyIncrementsMap.get(i)
                                        monthlyIncrementsMap.set(
                                            i,
                                            currentMapValue
                                                ? currentMapValue + monthlyDiscountPrice
                                                : monthlyDiscountPrice,
                                        )
                                    }
                                }
                            })
                        }
                    })
                })

                // Monthly Increments for Products
                // 350
                /**
                 * - Products, discounts, fees, discounts
                 *    Option, discounts, fees, discounts
                 * 0 - 50
                 * 1 - 50 up to 1st month - 300
                 * 2 - 35
                 * 3 - 35 up to 3rd month - 315
                 * 4 - 30 up to 4th month - 320
                 */

                // TODO: One time items for Products

                // OPTION CATEGORIES
                selectedProductCategory.selectedProduct.productTypes.forEach((productType) => {
                    const matchingProductType = selectedProductFromData.productTypes.find(
                        (pt) => pt.id === productType.id,
                    )
                    if (matchingProductType && matchingProductType.optional) {
                        const matchingProductTypePrice = b2b ? matchingProductType.priceNet : matchingProductType.gross
                        // Monthly Costs for Options
                        if (matchingProductType.billMode === BillMode.RECURRING_MONTHLY) {
                            costTableToReturn.monthlyCost += matchingProductTypePrice ?? 0
                        } else if (matchingProductType.billMode === BillMode.ONE_TIME) {
                            // One Time Costs for Options
                            costTableToReturn.oneTimeCost += matchingProductTypePrice ?? 0
                        }
                        matchingProductType.discounts.forEach((discount) => {
                            const discountPrice = b2b ? discount.priceNet : discount.gross
                            if (discount.billMode === BillMode.ONE_TIME) {
                                costTableToReturn.oneTimeCost -= discountPrice
                            } else if (discount.billMode === BillMode.RECURRING_MONTHLY) {
                                discount.monthlyDiscounts.forEach((monthlyDiscount) => {
                                    const monthlyDiscountPrice = b2b ? monthlyDiscount.priceNet : monthlyDiscount.gross
                                    if (monthlyDiscount.initMonth === 0 && monthlyDiscount.endMonth === 0) {
                                        costTableToReturn.monthlyCost -= monthlyDiscountPrice
                                    } else {
                                        for (let i = monthlyDiscount.initMonth; i < monthlyDiscount.endMonth; i++) {
                                            const currentMapValue = monthlyIncrementsMap.get(i)
                                            monthlyIncrementsMap.set(
                                                i,
                                                currentMapValue
                                                    ? currentMapValue + monthlyDiscountPrice
                                                    : monthlyDiscountPrice,
                                            )
                                        }
                                    }
                                })
                            }
                        })
                    }

                    productType.optionCategories.forEach((selectedOptionCategory: SelectedOptionCategory) => {
                        selectedProductFromData.productTypes.forEach((productType) => {
                            const matchingOptionCategory = productType.category.find(
                                (optionCategoryFromData: OptionCategory) =>
                                    optionCategoryFromData.id === selectedOptionCategory.id,
                            )

                            if (matchingOptionCategory) {
                                selectedOptionCategory.selectedOptions.forEach((optionId: string) => {
                                    const matchingOption = matchingOptionCategory.options.find(
                                        (option: Option) => option.id === optionId,
                                    )
                                    if (matchingOption) {
                                        const matchingOptionPrice = b2b ? matchingOption.priceNet : matchingOption.gross
                                        const optionsMultipleSelectCounter =
                                            optionsMultipleSelect.get(matchingOption.id) ?? 1
                                        // Monthly Costs for Options
                                        if (matchingOption.billMode === BillMode.RECURRING_MONTHLY) {
                                            costTableToReturn.monthlyCost +=
                                                matchingOptionPrice * optionsMultipleSelectCounter
                                        } else if (matchingOption.billMode === BillMode.ONE_TIME) {
                                            // One Time Costs for Options
                                            costTableToReturn.oneTimeCost +=
                                                matchingOptionPrice * optionsMultipleSelectCounter
                                        }

                                        matchingOption.fees.forEach((fee) => {
                                            if (
                                                (fee.processUnique && processUniqueFeeList.indexOf(fee.id) != -1) ||
                                                fee.optional
                                            ) {
                                                return
                                            } else if (fee.processUnique) {
                                                processUniqueFeeList.push(fee.id)
                                            }
                                            const feePrice = b2b ? fee.priceNet : fee.gross
                                            if (fee.billMode === BillMode.RECURRING_MONTHLY) {
                                                costTableToReturn.monthlyCost += feePrice
                                            } else if (fee.billMode === BillMode.ONE_TIME) {
                                                costTableToReturn.oneTimeCost += feePrice
                                            }
                                            fee.discounts.forEach((discount) => {
                                                const discountPrice = b2b ? discount.priceNet : discount.gross
                                                if (discount.billMode === BillMode.ONE_TIME) {
                                                    costTableToReturn.oneTimeCost -= discountPrice
                                                } else if (discount.billMode === BillMode.RECURRING_MONTHLY) {
                                                    discount.monthlyDiscounts.forEach((monthlyDiscount) => {
                                                        const monthlyDiscountPrice = b2b
                                                            ? monthlyDiscount.priceNet
                                                            : monthlyDiscount.gross
                                                        if (
                                                            monthlyDiscount.initMonth === 0 &&
                                                            monthlyDiscount.endMonth === 0
                                                        ) {
                                                            costTableToReturn.monthlyCost -= monthlyDiscountPrice
                                                        } else {
                                                            for (
                                                                let i = monthlyDiscount.initMonth;
                                                                i < monthlyDiscount.endMonth;
                                                                i++
                                                            ) {
                                                                const currentMapValue = monthlyIncrementsMap.get(i)
                                                                monthlyIncrementsMap.set(
                                                                    i,
                                                                    currentMapValue
                                                                        ? currentMapValue + monthlyDiscountPrice
                                                                        : monthlyDiscountPrice,
                                                                )
                                                            }
                                                        }
                                                    })
                                                }
                                            })
                                        })
                                        matchingOption.discounts.forEach((discount) => {
                                            const discountPrice = b2b ? discount.priceNet : discount.gross
                                            if (discount.billMode === BillMode.ONE_TIME) {
                                                costTableToReturn.oneTimeCost -= discountPrice
                                            } else if (discount.billMode === BillMode.RECURRING_MONTHLY) {
                                                discount.monthlyDiscounts.forEach((monthlyDiscount) => {
                                                    let monthlyDiscountPrice = b2b
                                                        ? monthlyDiscount.priceNet
                                                        : monthlyDiscount.gross
                                                    monthlyDiscountPrice *= optionsMultipleSelectCounter
                                                    if (
                                                        monthlyDiscount.initMonth === 0 &&
                                                        monthlyDiscount.endMonth === 0
                                                    ) {
                                                        costTableToReturn.monthlyCost -= monthlyDiscountPrice
                                                    } else {
                                                        for (
                                                            let i = monthlyDiscount.initMonth;
                                                            i < monthlyDiscount.endMonth;
                                                            i++
                                                        ) {
                                                            const currentMapValue = monthlyIncrementsMap.get(i)
                                                            monthlyIncrementsMap.set(
                                                                i,
                                                                currentMapValue
                                                                    ? currentMapValue + monthlyDiscountPrice
                                                                    : monthlyDiscountPrice,
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    })
                })
            }
        }
    })

    // One time cost installation service
    if (installationDetails.installationService !== 0) {
        costTableToReturn.oneTimeCost += installationDetails.installationService
        costTableToReturn.oneTimeItems.push({
            title: 'installationDetailsStrings.installationService.Title',
            value: {
                billMode: BillMode.IGNORE,
                newPrice: installationDetails.installationService,
            },
        })
    }

    // Monthly Increments for Products
    // 49.99
    /**
     * - Products, discounts, fees, discounts
     *    Option, discounts, fees, discounts
     * 0 - 30 = 19.99 <- BIG MONTHLY PRICE
     * 1 - 30 = 19.99
     * 2 - 20 = 29.99 <- after 2 month 29.99
     * 3 - 20 = 29.99
     *                <- after 4 month 49.99
     */

    monthlyIncrementsMap = new Map([...monthlyIncrementsMap.entries()].sort((a, b) => a[0] - b[0]))

    let lastPrice = -1
    let lowestPrice = -1

    monthlyIncrementsMap.forEach((value, key) => {
        const price = costTableToReturn.monthlyCost - value < 0 ? 0 : costTableToReturn.monthlyCost - value
        if (lastPrice === -1) {
            lowestPrice = costTableToReturn.monthlyCost - value
            lastPrice = costTableToReturn.monthlyCost - value
        } else {
            if (lastPrice !== price) {
                costTableToReturn.monthlyIncrements.push({
                    title: String(key + 1),
                    value: {
                        newPrice: price,
                        billMode: BillMode.IGNORE,
                    },
                })
                lastPrice = price
            }
        }
        if (key === monthlyIncrementsMap.size - 1) {
            costTableToReturn.monthlyIncrements.push({
                title: String(key + 2),
                value: {
                    billMode: BillMode.IGNORE,
                    newPrice: costTableToReturn.monthlyCost,
                },
            })
        }
    })

    if (costTableToReturn.oneTimeCost < 0) {
        costTableToReturn.oneTimeCost = 0
    }
    if (lowestPrice != -1) {
        costTableToReturn.monthlyCost = lowestPrice
    }
    if (costTableToReturn.monthlyCost < 0) {
        costTableToReturn.monthlyCost = 0
    }

    return costTableToReturn
}

export const basketCalculation = (
    availabilityCheckState: AvailabilityCheckState,
    bankDetailsState: BankDetailsState,
    generalState: GeneralState,
    b2b: boolean,
    isForVZF: boolean,
): BasketProps => {
    const productCategories: ProductTableCategoryProps[] = []
    const processUniqueFeeList: string[] = []
    const processUniqueVoucherList: string[] = []
    const customizeJsData = generalState.customizeJsData

    generalState.selectedProductCategories.forEach((selectedProductCategory: SelectedProductCategory) => {
        const formattedProductCategory = formatSelectedProductCategory(
            customizeJsData,
            bankDetailsState,
            generalState.optionsMultipleSelect,
            generalState.configuration,
            generalState.availableProductCategories,
            selectedProductCategory,
            processUniqueFeeList,
            processUniqueVoucherList,
            b2b,
            generalState.voucher,
            isForVZF,
        )
        if (!_.isEqual(formattedProductCategory, { title: '', products: [] })) {
            productCategories.push(formattedProductCategory)
        }
    })

    const costs = calculateBasketCosts(
        customizeJsData,
        bankDetailsState,
        generalState.optionsMultipleSelect,
        generalState.configuration,
        generalState.availableProductCategories,
        generalState.selectedProductCategories,
        generalState.installationDetails,
        b2b,
        isForVZF,
    )

    return {
        products: {
            address: {
                city: availabilityCheckState.selectedCity,
                houseNumber: availabilityCheckState.selectedHouseNumber,
                street: availabilityCheckState.selectedStreet,
                zip: availabilityCheckState.zip,
            },
            productCategories,
        },
        costs,
    }
}
