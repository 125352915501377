import { Box, Button, CircularProgress, Collapse, Fade, Grid, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import ConfigurationItem from 'components/ConfigurationItem'
import ErrorBox from 'components/ErrorBox'
import { useTranslation } from 'react-i18next'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'
import colors from 'utils/colors'
import { TouchPointEntry } from 'utils/customize'
import TestIDs from 'utils/TestIDs'
import { SendTouchPointState, useOrderConfirmationReducer } from './useOrderConfirmationReducer'

const OrderConfirmation = (): JSX.Element => {
    const { t } = useTranslation()
    const {
        loadState,
        salesPartner,
        newOrder,
        customizeJsData,
        selectedTouchPoint,
        setSelectedTouchPoint,
        onSubmit,
        sendTouchPointState,
        errorState,
    } = useOrderConfirmationReducer()

    const renderTouchPoint = (touchpoint: TouchPointEntry, index: number): JSX.Element => {
        const isSelected = selectedTouchPoint.selected === touchpoint.label
        return (
            <Grid key={index} container item className="TouchpointRadioWrapper">
                <ConfigurationItem
                    option={{
                        columnValue: 12,
                        label: t('orderConfirmationStrings.touchPoints.' + touchpoint.label),
                        mobileColumnValue: 12,
                        value: touchpoint.label,
                    }}
                    type={OptionType.RADIO}
                    paddingRight={'0px'}
                    selected={isSelected}
                    onSelect={(): void => {
                        if (!isSelected && sendTouchPointState === SendTouchPointState.READY) {
                            setSelectedTouchPoint({
                                selected: touchpoint.label,
                                input: '',
                            })
                        }
                    }}
                />
                <Fade in={isSelected && touchpoint.hasInput} unmountOnExit={true} exit={false}>
                    <TextField
                        style={{ marginTop: 8 }}
                        variant={'outlined'}
                        fullWidth={true}
                        label={t('orderConfirmationStrings.touchPoints.' + touchpoint.label)}
                        value={selectedTouchPoint.input}
                        inputProps={{
                            maxLength: 150,
                        }}
                        onChange={(e): void => {
                            setSelectedTouchPoint({
                                selected: touchpoint.label,
                                input: e.target.value,
                            })
                        }}
                        type={'text'}
                    />
                </Fade>
            </Grid>
        )
    }

    return (
        <Box
            minHeight={{ xs: 'calc(100vh - 300px)', sm: 'calc(100vh - 194px)' }}
            paddingLeft={3}
            paddingRight={3}
            paddingTop={2}
            paddingBottom={2}
        >
            {salesPartner && (
                <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={12}>
                        <Typography style={{ paddingBottom: 40 }} variant="h2">
                            {t('orderDone')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            size="large"
                            fullWidth
                            style={{ backgroundColor: colors.black, color: colors.white }}
                            variant="outlined"
                            onClick={() => newOrder()}
                        >
                            {t('newOrder')}
                        </Button>
                    </Grid>
                </Grid>
            )}
            {!salesPartner && (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2} />
                    <Grid item xs={12} md={10}>
                        <Typography
                            {...TestIDs.GET_ORDER_CONFIRMATION('HEADER_TYPOGRAPHY')}
                            variant={'h1'}
                            className={clsx({ TextError: !loadState.loading && loadState.errors })}
                        >
                            {!loadState.loading && loadState.errors
                                ? t(loadState.errors)
                                : t('orderConfirmationHeader')}
                        </Typography>
                        <Typography {...TestIDs.GET_ORDER_CONFIRMATION('SUBHEADER_TYPOGRAPHY')} variant={'h4'}>
                            {t('orderConfirmationSubheader')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} />
                    <Grid item xs={12} md={10}>
                        <Grid container item xs={12} lg={9} xl={7}>
                            <Fade in={loadState.loading} timeout={250} unmountOnExit={true} exit={false}>
                                <Box
                                    height={1}
                                    width={1}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    marginTop={5}
                                >
                                    <CircularProgress size={80} />
                                </Box>
                            </Fade>
                            <Fade in={!loadState.loading} timeout={250} unmountOnExit={true} exit={false}>
                                <Grid container {...TestIDs.GET_ORDER_CONFIRMATION('IMAGE')} spacing={3}>
                                    <Grid item xs={12}>
                                        <Box height={'100%'} width={'100%'} marginBottom={'32px'}>
                                            <img
                                                src={process.env.PUBLIC_URL + '/customize/thanks.png'}
                                                alt={'placeholder'}
                                                style={{ height: '100%', width: '100%' }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            {...TestIDs.GET_ORDER_CONFIRMATION('TEXT_1')}
                                            className={'UbersichtText'}
                                        >
                                            {t('orderConfirmationText1')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            {...TestIDs.GET_ORDER_CONFIRMATION('TEXT_2')}
                                            className={'UbersichtText'}
                                        >
                                            {t('orderConfirmationText2')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {customizeJsData &&
                customizeJsData.orderConfirmationConfiguratiuon.enableTouchPoints &&
                sendTouchPointState === SendTouchPointState.SENT && (
                    <Grid container spacing={3} className="TouchpointsArea">
                        <Grid item xs={12} md={2} />
                        <Grid container item xs={12} md={10}>
                            <Grid container item xs={12} lg={9} xl={7}>
                                <Typography variant="h2" className={'Title'}>
                                    {t('orderConfirmationStrings.touchPoints.feedbackSent')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            {customizeJsData &&
                customizeJsData.orderConfirmationConfiguratiuon.enableTouchPoints &&
                sendTouchPointState !== SendTouchPointState.SENT && (
                    <Grid container spacing={3} className="TouchpointsArea">
                        <Grid item xs={12} md={2} />
                        <Grid container item xs={12} md={10}>
                            <Grid container item xs={12} lg={9} xl={7}>
                                <Typography variant={'h2'} className={'Title'}>
                                    {t('orderConfirmationStrings.touchPoints.header')}
                                </Typography>
                                <Typography className={'Subtitle'}>
                                    {t('orderConfirmationStrings.touchPoints.subheader')}
                                </Typography>

                                <Grid container item style={{ marginBottom: 16 }}>
                                    {customizeJsData.orderConfirmationConfiguratiuon.touchPoints.map(renderTouchPoint)}
                                </Grid>
                                <Collapse
                                    in={errorState.trim().length > 0}
                                    unmountOnExit={true}
                                    timeout={250}
                                    disableStrictModeCompat={true}
                                >
                                    <Grid container item xs={12}>
                                        <ErrorBox errors={[{ category: '', errors: [errorState] }]} />
                                    </Grid>
                                </Collapse>
                                <Grid item xs={12} sm={12}>
                                    <Box
                                        display="flex"
                                        flex={1}
                                        justifyContent={{
                                            xs: 'stretch',
                                            sm: 'flex-end',
                                            md: 'flex-end',
                                            lg: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            disabled={
                                                selectedTouchPoint.selected.trim().length === 0 ||
                                                sendTouchPointState !== SendTouchPointState.READY
                                            }
                                            disableFocusRipple={true}
                                            className={clsx([
                                                'FormButton',
                                                'NextButton',
                                                'SendTouchpointButton',
                                                sendTouchPointState === SendTouchPointState.SENDING && 'Loading',
                                            ])}
                                            onClick={onSubmit}
                                        >
                                            {sendTouchPointState === SendTouchPointState.READY &&
                                                t('orderConfirmationStrings.touchPoints.submitButton')}
                                            {sendTouchPointState === SendTouchPointState.SENDING && (
                                                <>
                                                    {t('saveOrderProgressSending')}
                                                    <div className={'animation-loader'} />
                                                </>
                                            )}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
        </Box>
    )
}

export default OrderConfirmation
