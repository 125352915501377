import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import TaxInfo from 'components/TaxInfo'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import useURLParams from 'utils/URLParamsContex'
import colors from '../../utils/colors'
import { smallestMobile, smallMobile } from '../../utils/theme'
import CostsTable, { CostsTableProps } from '../CostsTable/CostsTable'
import ProductTable, { ProductTableProps } from '../ProductTable/ProductTable'

// Since there's no MuiBox in theme/override, we have to style them like this
// This also applies to img tags

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            zIndex: 1000,
            width: 'calc(320px)',
            backgroundColor: colors.lightGray,
            borderRadius: '5px',
            top: '90px',
            position: 'fixed',
            transition: 'right 250ms;',
            height: 'calc(100vh - 205px)',
            [theme.breakpoints.down(smallMobile)]: {
                top: 0,
                maxHeight: 'calc(100vh - 50px)',
            },
            [theme.breakpoints.down(smallestMobile)]: {
                top: 0,
                maxWidth: 'calc(320px - 80px)',
                height: 'calc(100vh - 80px)',
            },
        },
        Active: {
            right: '0px',
            boxShadow: '-5px 0 16px rgba(0,0,0,0.2)',
            border: '1px solid #FFF',
        },
        Inactive: {
            right: '-320px',
            [theme.breakpoints.down(smallestMobile)]: {
                right: '-240px',
            },
        },
        Tab: {
            height: '40px',
            zIndex: 3,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '0px',
            transition: 'right 250ms;',
            backgroundColor: colors.lightGray,
            transform: 'rotate(-90deg)',
            transformOrigin: '100% 100%',
            cursor: 'pointer',
            borderRadius: '5px 5px 0px 0px',
            [theme.breakpoints.down(smallMobile)]: {
                position: 'fixed',
            },
        },
        TabActive: {
            right: '320px',
            boxShadow: '0 -6px 10px -1px rgba(0,0,0,0.2)',
            borderColor: colors.white,
            borderStyle: 'solid',
            borderWidth: '1px 1px 0 1px',
            [theme.breakpoints.down(smallestMobile)]: {
                right: 'calc(319px - 80px)',
            },
        },
        TabInactive: {
            right: '320px',
            [theme.breakpoints.down(smallMobile)]: {
                position: 'fixed',
                right: '0px',
            },
        },
        TabIcon: {
            transition: 'transform 250ms',
        },
        TabIconActive: {
            transform: 'rotate(90deg) rotateY(-180deg)',
        },
        TabIconInactive: {
            transform: 'rotate(90deg)',
        },
        BasketContent: {
            height: 'calc(100vh - 205px)',
            overflowX: 'visible',
            overflowY: 'auto',
            width: '100%',
            [theme.breakpoints.down(smallMobile)]: {
                height: 'calc(100vh - 80px)',
            },
        },
    }),
    { name: 'Basket' },
)

export interface BasketProps {
    costs?: CostsTableProps
    products: ProductTableProps
    vzfID?: string
    testState?: boolean
}

const Basket = (props: BasketProps): JSX.Element => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { costs, products, vzfID, testState } = props

    const [isBasketOpen, setBasketOpen] = React.useState(testState ? testState : false)

    const theme = useTheme()
    const breakPointMatch = useMediaQuery(theme.breakpoints.up('xl'))
    const { B2B } = useURLParams()

    React.useEffect(() => {
        if (breakPointMatch && !isBasketOpen) {
            setBasketOpen(true)
        }
    }, [breakPointMatch])

    const toggleBasket = (): void => {
        document.body.className = !isBasketOpen ? 'noScroll' : ''
        setBasketOpen(!isBasketOpen)
    }

    return (
        <Box
            className={isBasketOpen ? clsx([classes.root, classes.Active]) : clsx([classes.root, classes.Inactive])}
            paddingBottom={2}
            paddingTop={3}
        >
            <Box
                className={
                    isBasketOpen
                        ? clsx(['BasketTab', classes.Tab, classes.TabActive])
                        : clsx(['BasketTab', classes.Tab, classes.TabInactive])
                }
                component={'span'}
                onClick={toggleBasket}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <img
                    width={'14px'}
                    height={'14px'}
                    className={
                        isBasketOpen
                            ? clsx([classes.TabIcon, classes.TabIconActive])
                            : clsx([classes.TabIcon, classes.TabIconInactive])
                    }
                    src={process.env.PUBLIC_URL + '/customize/basketTabIcon.svg'}
                    alt={'/\\'}
                    style={{ paddingLeft: '10px', paddingRight: '12px' }}
                />
                <Typography className={'BasketTabText'} noWrap={true}>
                    {t('basket')}
                </Typography>
                <img
                    width={'14px'}
                    height={'14px'}
                    className={
                        isBasketOpen
                            ? clsx([classes.TabIcon, classes.TabIconActive])
                            : clsx([classes.TabIcon, classes.TabIconInactive])
                    }
                    src={process.env.PUBLIC_URL + '/customize/basketTabIcon.svg'}
                    alt={'/\\'}
                    style={{ paddingLeft: '12px', paddingRight: '10px' }}
                />
            </Box>
            <Box className={classes.BasketContent}>
                <Grid item container className={'Basket'}>
                    <Box flex={1} paddingLeft={2} paddingRight={2}>
                        {vzfID && !B2B && (
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                paddingBottom={2}
                            >
                                <Typography variant={'h4'} className={'BasketVZFLabel'}>
                                    {t('vzf.vzfNumber')}
                                </Typography>
                                <Typography variant={'h5'}>{vzfID}</Typography>
                            </Box>
                        )}
                        <ProductTable {...products} isBasket={true} />
                    </Box>
                    {costs && (costs.monthlyCost > 0 || costs.oneTimeCost > 0) && (
                        <Box flex={1} paddingLeft={1} paddingRight={1}>
                            <CostsTable {...costs} isBasket={true} />
                        </Box>
                    )}
                </Grid>
                {costs && (costs.monthlyCost > 0 || costs.oneTimeCost > 0) && (
                    <TaxInfo variant={'caption'} style={{ padding: 10, color: colors.black }} displayFootNote={true} />
                )}
            </Box>
        </Box>
    )
}

export default Basket
