import { Box, Divider, Grid, Typography } from '@material-ui/core'
import TaxInfo from 'components/TaxInfo'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import colors from '../../utils/colors'
import { formatCurrency } from '../../utils/numberFormat'
import PriceDescription, { PriceDescriptionProps } from '../PriceDescription/PriceDescription'

export interface CostTableRow {
    title: string
    value: PriceDescriptionProps
}

export interface CostsTableProps {
    marginTop?: number
    monthlyCost: number
    monthlyIncrements: CostTableRow[]
    oneTimeCost: number
    oneTimeItems: CostTableRow[]
    isBasket?: boolean
}

const CostsTable = (props: CostsTableProps): JSX.Element => {
    const { t } = useTranslation()
    const { marginTop, monthlyCost, monthlyIncrements, oneTimeCost, oneTimeItems, isBasket } = props
    return (
        <Box className={'CostsTable'} padding={1} marginTop={marginTop !== undefined ? marginTop : 5} marginBottom={-1}>
            <Box width={1} height={'2px'} className={'CostsTableTopDivider'} style={{ opacity: 0 }} />
            <Grid container spacing={1}>
                <Grid item xs>
                    <Typography variant={'h5'} className={'CostsTableHeadline'}>
                        {t('monthlyCost')}
                    </Typography>
                </Grid>
                <Grid item xs={'auto'}>
                    <Box height={1} display={'flex'} alignItems={'center'}>
                        <Typography variant={'h5'} className={'CostsTableHeadline'}>
                            {formatCurrency(monthlyCost)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box width={1} marginTop={1} marginBottom={'4px'}>
                <Typography variant={'subtitle1'} className={'CostsTableSubtitle'}>
                    {t('startOfContract')}
                </Typography>
            </Box>
            {monthlyIncrements.map((tableRow: CostTableRow, index: number) => {
                return (
                    <Grid container key={index} className={'CostsTable'}>
                        <Grid item xs>
                            <Typography variant={'subtitle1'} className={'PriceDescriptionBasket'}>
                                {t('fromMonth', {
                                    month: tableRow.title,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <PriceDescription {...tableRow.value} isBasket={isBasket} isMonthlyIncrements={true} />
                        </Grid>
                    </Grid>
                )
            })}
            <Divider className={'CostsTableDivider'} />
            <Grid container spacing={1}>
                <Grid item xs>
                    <Typography variant={'h5'} className={'CostsTableHeadline'}>
                        {t('oneTimeCosts')}
                    </Typography>
                </Grid>
                <Grid item xs={'auto'}>
                    <Box height={1} display={'flex'} alignItems={'center'}>
                        <Typography variant={'h5'} className={'CostsTableHeadline'}>
                            {formatCurrency(oneTimeCost)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box width={1} marginTop={1} marginBottom={'4px'}>
                <Typography variant={'subtitle1'} className={'CostsTableSubtitle'}>
                    {t('yourOnlineAdvantage')}
                </Typography>
            </Box>
            {oneTimeItems.map((tableRow: CostTableRow, index: number) => (
                <Grid container key={index} className={'CostsTable'}>
                    <Grid item xs>
                        <Typography
                            className={'CostsTableRow'}
                            variant={'subtitle1'}
                            dangerouslySetInnerHTML={{ __html: t(tableRow.title) }}
                        />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PriceDescription {...tableRow.value} isBasket={isBasket} isMonthlyIncrements={true} />
                    </Grid>
                </Grid>
            ))}
            {!isBasket && (
                <TaxInfo variant={'caption'} style={{ paddingTop: 10, color: colors.white }} displayFootNote={false} />
            )}
        </Box>
    )
}

export default CostsTable
