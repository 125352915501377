import { Link } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'

const HelpLink = (): JSX.Element => {
    const { t } = useTranslation()
    return (
        <Link href={'tel:03413504555'} {...TestIDs.GET_HEADER_COMPONENT('LOGOICON_BUTTON')} className={'HelpLink'}>
            {t('help')}
            {/* <img
                src={process.env.PUBLIC_URL + '/customize/help.svg'}
                alt={'placeholder'}
                style={{ height: 20, width: 20 }}
            /> */}
            {t('telephoneNumber')}
        </Link>
    )
}

export default HelpLink
