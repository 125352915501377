import { Collapse, Grid } from '@material-ui/core'
import ConfigurationCheck from 'components/ConfigurationCheck'
import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import ConfigurationRadio from 'components/ConfigurationRadio'
import ViewWrapper from 'components/ViewWrapper'
import { cloneDeep, isEqual } from 'lodash'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigPayload } from 'store/GeneralState/GeneralState.actions'
import { Page } from 'store/GeneralState/GeneralState.reducer'
import {
    ConfigurationDisplayType,
    ConfigurationType,
    Customize,
    OptionConfiguration,
    TelephoneConfiguration,
} from 'utils/customize'
import { getRoleAwareTranslationKey, UserInfo, useUserInfo } from 'utils/UserInfoContext'
import { useTelephoneConfigurationViewReducer } from './useTelephoneConfigurationViewReducer'

export interface RenderTelephoneConfigurationViewProps {
    customizeJsData: Customize | undefined
    configurationMapState: Map<string, string | string[]>
    setEditConfigurationMapState?: Dispatch<SetStateAction<Map<string, string | string[]>>>
    setConfigurationMapRadioState: (payload: ConfigPayload) => void
    setConfigurationMapCheckState: (payload: ConfigPayload) => void
    convertOptionToOptionItem: (
        option: OptionConfiguration,
        displayType: ConfigurationDisplayType,
        optionsLength: number,
    ) => ConfigurationItemOption
    resetPortabilityState?: () => void
    userInfo: UserInfo | null
    pagesList?: Page[]
}

export const renderTelephoneConfigurationView = (props: RenderTelephoneConfigurationViewProps): JSX.Element => {
    const {
        configurationMapState,
        convertOptionToOptionItem,
        customizeJsData,
        resetPortabilityState,
        setConfigurationMapCheckState,
        setConfigurationMapRadioState,
        userInfo,
        setEditConfigurationMapState,
    } = props
    const renderTelephoneConfiguration = (
        telephoneConfiguration: TelephoneConfiguration,
        index: number,
    ): JSX.Element => {
        if (telephoneConfiguration.type === ConfigurationType.CHECKBOXES) {
            let newOptionCheck: ConfigurationItemOption[] = []
            if (telephoneConfiguration.options) {
                const opts = telephoneConfiguration.options
                newOptionCheck = opts.map((option: OptionConfiguration): ConfigurationItemOption => {
                    return convertOptionToOptionItem(
                        option,
                        telephoneConfiguration.displayType
                            ? telephoneConfiguration.displayType
                            : ConfigurationDisplayType.MULTI_LINE,
                        opts.length,
                    )
                })
            }
            return (
                <ConfigurationCheck
                    key={telephoneConfiguration.identifier + index}
                    titleHidden={telephoneConfiguration.titleHidden}
                    title={getRoleAwareTranslationKey(userInfo, telephoneConfiguration.identifier)}
                    helpText={telephoneConfiguration.helpText}
                    selected={configurationMapState.get(telephoneConfiguration.identifier) as string[]}
                    link={telephoneConfiguration.link}
                    options={newOptionCheck}
                    onSelect={(value: string): void => {
                        if (setEditConfigurationMapState) {
                            const configurationMapStateCopyArray = [...cloneDeep(configurationMapState).entries()]
                            const found = configurationMapStateCopyArray.find(
                                (entry) => entry[0] === telephoneConfiguration.identifier,
                            )
                            if (found) {
                                configurationMapStateCopyArray.forEach((entry, index) => {
                                    if (entry[0] === telephoneConfiguration.identifier) {
                                        if (typeof entry[1] === 'object') {
                                            const temp = entry[1] as string[]
                                            const foundIndex = temp.findIndex((x) => x === value)
                                            if (foundIndex !== -1) {
                                                temp.splice(foundIndex, 1)
                                            } else {
                                                temp.push(value)
                                            }
                                            if (entry[0] !== 'numbersActivate' && temp.length === 0) {
                                                configurationMapStateCopyArray.splice(index, 1)
                                            }
                                        }
                                    }
                                })
                            } else {
                                const newEntry: [string, string | string[]] = [
                                    telephoneConfiguration.identifier,
                                    [value],
                                ]
                                configurationMapStateCopyArray.push(newEntry)
                            }
                            const configurationMapStateCopy = new Map(configurationMapStateCopyArray)
                            setEditConfigurationMapState(configurationMapStateCopy)
                        } else {
                            setConfigurationMapCheckState({
                                identifier: telephoneConfiguration.identifier,
                                value,
                            })
                        }
                    }}
                    marginBottom={telephoneConfiguration.noMarginBottom ? 0 : undefined}
                    marginTop={telephoneConfiguration.marginTop}
                />
            )
        } else if (telephoneConfiguration.type === ConfigurationType.RADIO_BUTTONS) {
            const newOptionRadio: ConfigurationItemOption[] = telephoneConfiguration.options
                ? telephoneConfiguration.options.map((option: OptionConfiguration): ConfigurationItemOption => {
                      return convertOptionToOptionItem(
                          option,
                          telephoneConfiguration.displayType
                              ? telephoneConfiguration.displayType
                              : ConfigurationDisplayType.MULTI_LINE,
                          telephoneConfiguration.options ? telephoneConfiguration.options.length : 0,
                      )
                  })
                : []
            let selected = ''
            const selectedValue = configurationMapState.get(telephoneConfiguration.identifier)
            if (selectedValue instanceof Array && typeof selectedValue[0] === 'string') {
                selected = selectedValue[0]
            } else if (typeof selectedValue === 'string') {
                selected = selectedValue
            }
            return (
                <ConfigurationRadio
                    key={telephoneConfiguration.identifier + index}
                    titleHidden={telephoneConfiguration.titleHidden}
                    title={getRoleAwareTranslationKey(userInfo, telephoneConfiguration.identifier)}
                    helpText={telephoneConfiguration.helpText}
                    selected={selected}
                    link={telephoneConfiguration.link}
                    options={newOptionRadio}
                    onSelect={(value: string): void => {
                        if (setEditConfigurationMapState) {
                            const configurationMapStateCopyArray = [...cloneDeep(configurationMapState)]
                            configurationMapStateCopyArray.forEach((entry) => {
                                if (entry[0] === telephoneConfiguration.identifier) {
                                    entry[1] = value
                                }
                            })
                            const configurationMapStateCopy = new Map(configurationMapStateCopyArray)
                            setEditConfigurationMapState(configurationMapStateCopy)
                        } else {
                            setConfigurationMapRadioState({
                                identifier: telephoneConfiguration.identifier,
                                value,
                            })
                            if (telephoneConfiguration.identifier === 'takeYourNumber' && value === 'newPhone') {
                                resetPortabilityState && resetPortabilityState()
                            }
                        }
                    }}
                    marginBottom={telephoneConfiguration.noMarginBottom ? 0 : undefined}
                    marginTop={telephoneConfiguration.marginTop}
                />
            )
        } else if (
            telephoneConfiguration.type === ConfigurationType.CONDITIONAL &&
            telephoneConfiguration.condition &&
            telephoneConfiguration.render
        ) {
            const condition = telephoneConfiguration.condition
            const conditionalValue = configurationMapState.get(condition.identifier)

            return (
                <Collapse
                    key={telephoneConfiguration.identifier + index}
                    in={conditionalValue !== undefined && isEqual(conditionalValue, condition.value)}
                    onExited={(): void => {
                        configurationMapState.delete('entryLocation')
                        configurationMapState.delete('informationServices')
                        configurationMapState.set('addressAdditionInPhoneBook', 'withAddressInPhoneBook')
                    }}
                >
                    <Grid item xs={12}>
                        {telephoneConfiguration.render.map(renderTelephoneConfiguration)}
                    </Grid>
                </Collapse>
            )
        } else {
            return <div />
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {customizeJsData &&
                    customizeJsData.telephoneOptions.telephoneConfiguration.map(renderTelephoneConfiguration)}
            </Grid>
        </Grid>
    )
}

const TelephoneConfigurationView = (): JSX.Element => {
    const { t } = useTranslation()

    const {
        configurationMapState,
        convertOptionToOptionItem,
        currentView,
        customizeJsData,
        disabledSubmit,
        setConfigurationMapCheckState,
        setConfigurationMapRadioState,
        resetPortabilityState,
    } = useTelephoneConfigurationViewReducer()

    const [userInfo] = useUserInfo()

    return (
        <ViewWrapper
            disabledSubmit={disabledSubmit}
            header={getRoleAwareTranslationKey(userInfo, 'landlineContractOptionsHeader')}
            subHeader={getRoleAwareTranslationKey(userInfo, 'landlineContractOptionsSubheader')}
            viewType={currentView}
            submitButtonText={
                customizeJsData && customizeJsData.telephoneOptions.portabilityVisible
                    ? configurationMapState.get(customizeJsData.portabilityConfiguration.showPortability.identifier) ===
                      customizeJsData.portabilityConfiguration.showPortability.wishValue
                        ? t(currentView + 'Strings.goTo', {
                              view: t(currentView + 'Strings.portability'),
                          })
                        : t(currentView + 'Strings.goTo', {
                              view: t(currentView + 'Strings.bank'),
                          })
                    : undefined
            }
        >
            {renderTelephoneConfigurationView({
                configurationMapState,
                convertOptionToOptionItem,
                customizeJsData,
                resetPortabilityState,
                setConfigurationMapCheckState,
                setConfigurationMapRadioState,
                userInfo,
            })}
        </ViewWrapper>
    )
}

export default TelephoneConfigurationView
